//import { useTheme } from '@mui/material/styles';

const LoginStyles = () => {
  //const theme = useTheme();

  return {
    container: {
      marginTop: "1vh",
    }
  };
};

export default LoginStyles;
