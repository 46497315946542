//import { useTheme } from '@mui/material/styles';

const RegisterStyles = () => {
    //const theme = useTheme();
  
    return {
      container: {
          marginTop: "1vh",
          padding: "10px"
      }
    };
  };
  
  export default RegisterStyles;
  