//import { useTheme } from '@mui/material/styles';

const PaymentStyle = () => {
    //const theme = useTheme();
  
    return {
      container: {
        marginTop: "3vh",
        marginBottom: "10vh",
      }
    };
  };
  
  export default PaymentStyle;
  